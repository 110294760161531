import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './components/payment/payment.component';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [PaymentComponent],
  exports: [
    PaymentComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    FontAwesomeModule,
    TranslateModule,
    SharedModule
  ]
})
export class PaymentModule { }
