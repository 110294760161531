import { Component, Input, OnInit } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'flwr-back-button-bar',
  templateUrl: './back-button-bar.component.html',
  styleUrls: ['./back-button-bar.component.scss']
})
export class BackButtonBarComponent implements OnInit {
  // icons in template
  public faArrowLeft = faArrowLeft;

  //variables
  @Input() backRoute: Array<string>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
