import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[SlcFullWidthHeightContainer]'
})
export class SlcFullWidthHeightContainerDirective {

  constructor() {
  }

  @HostBinding('class.slc-full-width-height-container')
  get fullHeight() {
    return 'slc-full-width-height-container'
  }
}
