import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalInformationFormComponent } from './components/personal-information-form/personal-information-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { SlcInputModule } from '../../slice-theme/modules/slc-input/slc-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputValidateErrorsModule } from '../input-validate-errors/input-validate-errors.module';
import { SharedModule } from '../../shared/shared.module';
import { SlcButtonModule } from '../../slice-theme/modules/slc-button/slc-button.module';
import { SlcSpinnerModule } from '../../slice-theme/modules/slc-spinner/slc-spinner.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SlcLoadingModule } from '../slc-loading/slc-loading.module';



@NgModule({
  declarations: [PersonalInformationFormComponent],
  exports: [
    PersonalInformationFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SlcInputModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    InputValidateErrorsModule,
    SharedModule,
    SlcButtonModule,
    SlcSpinnerModule,
    MatProgressSpinnerModule,
    SlcLoadingModule
  ]
})
export class PersonalInformationFormModule { }
