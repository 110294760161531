import { Injectable } from '@angular/core';
import { Paginator } from '../../../../../shared/classes/paginator';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BouquetOrderList } from '../../../../../shared/models/bouqrete';
import { B2bOrderList } from '../../../../../shared/models/flower';



@Injectable({
  providedIn: 'root'
})
export class B2bOrderHistoryService {

  constructor(private http: HttpClient) { }


  public getFlowersOrders(paginator?: Paginator<B2bOrderList>): Observable<any[] | any> {
    if (paginator) {
      return this.http.get<any[]>(`${environment.backendUrl}/business/order-flower?pagination=true`, {params: paginator.getNextPageParams()})
    } else {
      return this.http.get<any[]>(`${environment.backendUrl}/business/order-flower`)
    }
  }
  public getSingleFlowerDetail(orderId): Observable<any[] | any> {
      return this.http.get<any[]>(`${environment.backendUrl}/business/order-flower/${orderId}`)
  }
}
