import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConditionConfirmationComponent } from './components/condition-confirmation/condition-confirmation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputValidateErrorsModule } from '../input-validate-errors/input-validate-errors.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [ConditionConfirmationComponent],
  exports: [
    ConditionConfirmationComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        InputValidateErrorsModule,
        RouterModule
    ]
})
export class CondtitionConfirmationFormModule { }
