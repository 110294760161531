import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlcFullWidthHeightContainerDirective } from './directives/slc-full-width-height-container.directive';



@NgModule({
  declarations: [SlcFullWidthHeightContainerDirective],
  exports: [
    SlcFullWidthHeightContainerDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SlcFullHeightContainerModule { }
