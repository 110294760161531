import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PhoneNumberModel, UserModel } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService {

  constructor(private http: HttpClient) {
  }


  public changePhoneNumber(phoneNumber: PhoneNumberModel): Observable<any> {
    return this.http.put(`${environment.backendUrl}/customer/phone-number/change`,
      {phoneNumber});
  }

  public verifyPhoneNumber(verificationCode: string): Observable<UserModel> {
    return this.http.put<UserModel>(`${environment.backendUrl}/customer/phone-number/verify`,
      {verificationCode});
  }
}
