import { Injectable } from '@angular/core';
import { FlowerModel } from '../../../../../shared/models/flower';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FLWR_B2B_CUSTOMER_CART } from '../../../../../config';


@Injectable({
  providedIn: 'root'
})
export class B2bCartService {

  public b2bCartProduct = new FormArray([]);


  constructor() {
  }


  /**
   * add product to cart
   *
   * @param flower
   * @param count
   */
  addProductToCart(flower: FlowerModel, count) {
    let cartHasFlower: boolean = false;
    this.b2bCartProduct.controls.some(
      (product, index) => {
        if (product['controls'].item.value.publicId === flower.publicId) {
          this.b2bCartProduct.controls[index]['controls'].count.setValue(this.b2bCartProduct.controls[index]['controls'].count.value + count);
          cartHasFlower = true;
          return true;
        }
      }
    );
    if (!cartHasFlower) {
      this.b2bCartProduct.push(new FormGroup({
        count: new FormControl(count, [Validators.required, Validators.min(1), Validators.max(99)]),
        item: new FormControl(flower),
        isOutOfSeason: new FormControl(false, this.validateSeason),
        isUnavailable: new FormControl(false, this.validateAvailability)
      }));
    }
    localStorage.setItem(FLWR_B2B_CUSTOMER_CART, JSON.stringify(this.b2bCartProduct.value));
  }


  /**
   * get b2b cart from local storage if have some
   */
  public getCustomerB2bCartFromLocalStorage(): void {
    this.b2bCartProduct.clear();
    if (localStorage.getItem(FLWR_B2B_CUSTOMER_CART)) {
      const cartB2bProductsLocalStorage = JSON.parse(localStorage.getItem(FLWR_B2B_CUSTOMER_CART));
      cartB2bProductsLocalStorage.forEach(singleProduct => {
        this.b2bCartProduct.push(new FormGroup({
          count: new FormControl(singleProduct.count, [Validators.required, Validators.min(1), Validators.max(99)]),
          item: new FormControl(singleProduct.item),
          isOutOfSeason: new FormControl(false, this.validateSeason),
          isUnavailable: new FormControl(false, this.validateAvailability)
        }));
      });
    }
  }

  /**
   * remove
   * @param index of flowers
   */
  public removeFromB2bCart(index) {
    this.b2bCartProduct.removeAt(index);
    localStorage.setItem(FLWR_B2B_CUSTOMER_CART, JSON.stringify(this.b2bCartProduct.value));
  }


  /**
   * remove whole b2b cart from localstorage
   * often after success order
   */
  public removeWholeCartFromLocalStorage() {
    if (localStorage.getItem(FLWR_B2B_CUSTOMER_CART)) {
      localStorage.removeItem(FLWR_B2B_CUSTOMER_CART);
    }
  }

  /**
   * validator for OutOfSeason
   * @param control: from control
   */
  validateSeason(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    } else {
      return {isOutOfSeason: true};
    }
  }

  /**
   * validator for Unavailable
   * @param control: from control
   */
  validateAvailability(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    } else {
      return {isUnavailable: true};
    }
  }

}
